import React from "react";
import { BUTTON_VARIANTS, Button } from "../components/Button";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className={`w-full h-full`}>
      <div
        className={`
      pt-7  bg-black-100
      `}
      >
        <div>
          <div
            className={`pt-8 text-white opacity-90 font-sans text-2xl flex flex-row justify-center`}
          >
            Privacy Policy
          </div>
          <div className="text-center flex flex-row justify-center text-white opacity-60 pb-8 text-xs">
            Last Updated: March, 2024
          </div>
          <div className="flex flex-row justify-center">
            <div className={`overflow-y-auto h-[85vh] w-[80%] `}>
              <div>
                <div>
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    1. Introduction
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    Generative Genius, LLC (“we”, “us”, “our,” or “Company”)
                    respects the privacy of our users. This Privacy Policy
                    outlines how we collect, use, protect, and share your
                    personal data when you use our services (“Services”). This
                    Privacy Policy is designed to inform you of your privacy
                    rights.
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-4">
                    By accessing the Services, you are agreeing to and accepting
                    the terms of this Privacy Policy as well as the Terms of
                    Services.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    2. Information We Collect
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    You can input, upload, and store information in the
                    Services, including text, images, and other data
                    (“Content”). We also collect the following types of personal
                    data:
                  </div>
                  <ul className="pt-6 ml-4 text-white text-[15px] opacity-70 list-disc list-inside">
                    <li className="pb-2 ">
                      Identity Data: Names: We collect information that you may
                      provide to us regarding your identity, including your
                      name, professional title, and other identifiers.
                    </li>
                    <li className="pb-2">
                      Contact Data: Email addresses and other contact details.
                    </li>
                    <li className="pb-2">
                      Professional Data: Information regarding your
                      qualifications.
                    </li>
                    <li className="pb-2">
                      Biometric and Health-related Data: This is within the use
                      case of healthcare tasks you submit to the AI
                    </li>
                    <li className="pb-2">
                      Financial Data: This is within the use case of financial
                      tasks you submit to the AI.
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    3. Data Privacy and Security
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    We prioritize the security of your data. We implement robust
                    security measures to prevent unauthorized access,
                    disclosure, or alteration of your personal information. We
                    are actively pursuing certifications through 3rd party
                    auditors to achieve and maintain compliance with the Health
                    Insurance Portability and Accountability Act (HIPAA), SOC 2,
                    and the Health Information Technology for Economic and
                    Clinical Health Act (HITECH), ensuring the highest standards
                    of data protection and security. At this time we are in the
                    beta test phase which does NOT provide the above mentioned
                    certifications.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    4. How We Use Your Information
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    Your personal data is used to provide and improve our
                    Services, comply with legal obligations, and enhance your
                    user experience. We process your data for:
                  </div>
                  <ul className="pt-6 ml-4 text-white text-[15px] opacity-70 list-disc list-inside">
                    <li className="pb-5">
                        To Perform and Personalize the Services: We will use
                        your data to provide you the Services, manage our
                        relationship with you, including providing our Services
                        and responding to inquiries and engaging in other
                        communications relevant to the Services.
                    </li>
                    <li className="pb-5">
                        Legal Compliance: We will use your data to satisfy
                        applicable laws or regulations, and disclose information
                        in response to legal process or enforceable government
                        requests, including to law enforcement, or in response
                        to government processes, such as court orders and
                        subpoenas.
                    </li>
                    <li className="pb-5">
                        Improvement and Analysis: We will use your data to
                        enhance the functionality and security of our Services,
                        to conduct analysis of the data for internal research
                        and development purposes, and to work to provide you a
                        better user experience with respect to our Services.
                    </li>
                    <li className="pb-5">
                        Communication: We will use your data to communicate with
                        you, including administrative messages, technical,
                        notices, security alerts, updating you about changes to
                        our Services, Terms of Services, and Privacy Policy,
                        etc. If we learn of a security system breach, we may
                        attempt to notify you and provide information on
                        protective steps, if available, through the email
                        address, phone number, or other contact information that
                        you have provided to us.
                    </li>
                    <li className="pb-5">
                        Protect Against Security Threats, Abuse, and Illegal
                        Activity. As part of our efforts to protect your account
                        and personal data, our systems may analyze emails you
                        send to and from your account and the notes you share to
                        detect malware, spam, or other potential security
                        concerns. In the event we determined there is a security
                        threat or purported violation of our Terms of Services
                        or Privacy Policy, we may block delivery of or unsure
                        the problematic materials, e.g., like a spam filter
                        works with email.
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    5. Sharing Your Information
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    We may share your information with third parties, including
                    service providers, professional advisors who are under
                    contractual or fiduciary requirements to keep your personal
                    information strictly confidential and law enforcement
                    agencies to comply with legal obligations.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    6. How Long Do We Store Your Data
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    We make the Content that you store within the Services
                    readily accessible to you, until you make the decision to
                    delete it. If you delete your Content then it will no longer
                    be available to you.
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-3">
                    Any other information is retained as long as needed to
                    fulfil our contractual obligations to you or as legally
                    required by law.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    7. Minors
                  </div>
                  <span className="font-sans text-white  text-[15px] pt-1">
                    <span className="opacity-70">
                    The Services are meant for adults. We do not intend to
                    collect personal information from children under the age of
                    16. If we learn we have collected personal data from a child
                    under 16 without verification of parental consent, we will
                    delete that information. If you believe we might have any
                    information from or about a child under 16, please contact
                    us at
                    </span>
                    <span className="font-bold ml-2">
                      [Andrew.Hancock@GenerativeGeniuses.com].
                    </span>
                  </span>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    8. Changes to This Policy
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    We may update this Privacy Policy from time to time. We
                    encourage you to review it regularly to stay informed about
                    how we are protecting your data.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    9. How We Respond to Do Not Track Signals
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    Do Not Track is a privacy preference that users can set in
                    their web browsers. When a user turns on the Do Not Track
                    signal, the browser sends a message to websites requesting
                    them not to track the user. At this time, we do not
                    currently respond to or otherwise take action in response to
                    web browser “do not track” signals. For information about Do
                    Not Track, visit{" "}
                    <a href="www.allaboutdnt.org.">www.allaboutdnt.org.</a>
                  </span>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    10. Implementation and Accuracy of Privacy Practices
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    We are fully committed to implementing our Privacy Policy
                    with the highest degree of accuracy at all times. Our
                    processes for handling personal data are designed to ensure
                    compliance with this Privacy Policy and all applicable data
                    protection laws and standards. In the unlikely event that
                    our practices do not fully align with our stated Privacy
                    Policy, or if adjustments are necessary due to changes in
                    legal requirements, we will take immediate action to rectify
                    the situation. This includes promptly notifying affected
                    individuals and taking corrective measures to uphold our
                    commitment to data privacy and security.
                  </div>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px]">
                    11. Notices and Contact
                  </div>
                 <div className="my-3">
                 <span className="font-sans text-white opacity-70 text-[15px] pt-1 mb-2">
                    11.1 <span className="font-bold">Notices to You</span>: We
                    may provide you with notices through the Service or by
                    electronic mail to your e-mail address on record. You are
                    responsible for ensuring that your contact information is
                    both current and accurate.
                  </span>
                 </div>
                  <span className="font-sans text-white  text-[15px] pt-1">
                    11.2 <span className="font-bold opacity-70">Your Notice to Us:</span>
                    <span className="opacity-70">For any questions or concerns about our privacy practices,
                    or to reach our designated copyright or intellectual
                    property agent please contact us at</span>
                    <span className="font-bold ml-3">
                      [Andrew.Hancock@GenerativeGeniuses.com].
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
