import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);

const Billing = () => {
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const handleCheckout = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        `${getAPIBaseUrl()}/create-checkout-session`,
        {
          email: currentUser.email, 
        }
      );

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center p-4">
      <div className="flex flex-col lg:flex-row w-full max-w-7xl lg:space-x-12 space-y-8 lg:space-y-0">
        <div className="flex-1 bg-white p-6 sm:p-8 shadow-lg rounded-lg">
          <h2 className="text-lg font-semibold">
            Subscribe to GG Plus Subscription
          </h2>
          <p className="text-3xl font-bold mt-4">
            US$1.00 <span className="text-base font-normal">per month</span>
          </p>

          <div className="mt-6">
            <div className="flex justify-between text-sm text-gray-600">
              <p>GG Plus Subscription</p>
              <p>US$1.00</p>
            </div>
            <p className="text-xs text-gray-500 mt-1">Billed monthly</p>
          </div>

          <div className="border-t my-4"></div>

          <div className="flex justify-between text-sm text-gray-600">
            <p>Subtotal</p>
            <p>US$1.00</p>
          </div>

          <div className="border-t my-4"></div>

          <div className="flex justify-between items-center text-lg font-semibold">
            <p>Total due today</p>
            <p>US$1.00</p>
          </div>
          <div className="flex flex-row justify-center bg-white p-6 sm:p-8  rounded-lg">
            <button onClick={handleCheckout} disabled={loading}
            className="bg-indigo-500 text-white py-2 px-6 rounded-md h-12 "
            >
              {loading ? "Processing..." : "Subscribe"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
