import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import ChatCard from "../../components/ChatCard";
import useAI from "../../hooks/useAI";
import stopStreamIcon from "../../assets/stop-stream.png";
import { LuSend } from "react-icons/lu";
import { helpPrompts, loadingMsgs } from "../../utils";
import { IoIosAttach, IoIosMedkit } from "react-icons/io";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { FaFileAlt } from "react-icons/fa";
import { IoClose, IoCloseCircle, IoEyeOutline } from "react-icons/io5";
import Loader from "../../components/Loader";
import { CustomModal } from "../../components/CustomModal";
import Login from "../auth/Login";
import { setChatId, setUserDetails } from "../../redux/actions/authActions";
import Dropdown from "rc-dropdown";
import logoutIcon from "../../assets/log-out.png";
import hamburgerMenu from "../../assets/Hamburger.png";
import "rc-dropdown/assets/index.css";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import Signup from "../auth/Signup";
import { v4 as uuidv4 } from "uuid";
import { BiChevronUpCircle, BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin7Line } from "react-icons/ri";
import {
  MdArrowDownward,
  MdContentCopy,
  MdDriveFileRenameOutline,
  MdOutlineExpandCircleDown,
  MdOutlinePayment,
} from "react-icons/md";
import Input from "../../components/Input";
import { IoCloseSharp } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareReddit } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import ChatUiSkeleton from "../../components/ChatUiSkeleton";
import userIcon from "../../assets/user.png";
import Settings from "../../components/Settings";
import { IoMdSettings } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineLogout } from "react-icons/ai";
import { useMsal } from "@azure/msal-react";
import { GoDownload, GoQuestion } from "react-icons/go";
import ggLogo from "../../assets/gg-light.png";
import { ImFilesEmpty } from "react-icons/im";
import ggDarkLogo from "../../assets/gg-dark.png";

const getRandomElements = (arr, numElements = 4) => {
  const shuffledArray = arr.sort(() => 0.5 - Math.random());

  return shuffledArray.slice(0, numElements);
};

const DesktopChatScreen = () => {
  const {
    dispatch,
    chatMsgsToDisplay,
    emitToServer,
    chatMsgsForServer,
    latestStream,
    showLoading,
    isStreaming,
  } = useAI("ai_chat", "ai_chat");
  const { instance, accounts } = useMsal();

  const inputRef = useRef();
  const chatInputRef = useRef();
  const scrollContainerRef = useRef();
  const fileInputRef = useRef();

  const storeDispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser, userChatId } = useSelector((state) => state.auth);

  const { chatId } = useParams();

  // auth states
  const [authLoading, setAuthLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isLoginModal, setLoginModal] = useState(false);
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [rows, setRows] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false); // To track manual scrolling
  const [loadingText, setLoadingText] = useState(
    loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
  );
  const [loadingDotsDirection, setLoadingDotsDirection] = useState("up");
  const [prompts, setPrompts] = useState(getRandomElements(helpPrompts));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadFileLoading, setUploadFileLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState("");
  const [updatedChatTitle, setUpdatedChatTitle] = useState("");
  const [isShareChatModal, setShareChatModal] = useState(false);
  const [isCopyLoading, setCopyLoading] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [isChatMsgLoading, setChatMsgLoading] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [selectedTheme, setTheme] = useState("light");
  const [files, setFiles] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState("");
  const [isChatHistoryExpanded, setChatHistoryExpanded] = useState(false);
  const [isFilesExpanded, setFilesExpanded] = useState(false);

  const theme = localStorage.getItem("theme") || "light";

  const navigateToSignup = () => {
    setSignUpModal(true);
    setLoginModal(false);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const navigateToLogin = () => {
    setSignUpModal(false);
    setLoginModal(true);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const saveUpdatedChatTitle = async () => {
    try {
      const response = await axios.put(`${getAPIBaseUrl()}/rename-chat`, {
        chat_id: selectedChat,
        new_title: updatedChatTitle,
      });
      toast.success("Chat title updated successfully");
      setSelectedChat("");
      setUpdatedChatTitle("");
      await getSavedChatList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        storeDispatch(setUserDetails(response?.data?.user));
        toast.success("Logged in successfully");
        setLoginModal(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Logged in failed ! Please try again !");
    }
  };

  const signupHandler = async (email, password, name) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
      name,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setSignupErrorMessage("");
      setSignUpModal(false);
      toast.success("Sign up successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
      toast.error("Sign up failed ! Try again !");
    }
  };

  const loginHandler = async (email, password) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setLoginErrorMessage("");
      setLoginModal(false);
      toast.success("Logged in successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setLoginErrorMessage(error?.response?.data?.error);
      toast.error("Logged in failed ! Try again !");
    }
  };

  const logoutUser = () => {
    storeDispatch(setUserDetails({}));
    googleLogout();
    resetChatToDisplay();
    navigate("/login");
  };

  const deleteUserHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      logoutUser();
      setSettingsOpen(false);
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const resetChatToDisplay = () => {
    dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: [],
        chatMsgsForServer: [],
      },
    });
  };

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      getSavedChatList();
      setSettingsOpen(false);
      resetChatToDisplay();
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const uploadFileHandler = async (event) => {
    const selectedFiles = event.target.files;

    setUploadFileLoading(true);
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    if (currentUser && Object.keys(currentUser).length > 0 && chatId) {
      formData.append("user_email", currentUser.email);
      formData.append("chat_id", chatId);
    }

    try {
      const response = await axios.post(`${getAPIBaseUrl()}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadedFiles(response.data?.files);
      setUploadFileLoading(false);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setUploadFileLoading(false);
    }
  };

  // const removeFileHandler = (index) => {
  //   const newFileIds = [...uploadedFileIds];
  //   newFileIds.splice(index, 1);

  //   setUploadedFileIds(newFiles);

  //   const newFiles = [...uploadedFiles];
  //   newFiles.splice(index, 1);

  //   setUploadedFiles(newFiles);
  // };

  const renderUploadedFile = () => {
    if (!uploadedFiles || uploadedFiles.length === 0) return null;

    return (
      <div
        className={` flex flex-row gap-4 h-18 ${
          theme === "dark" ? "bg-black-200" : "bg-neutral-50"
        } rounded-t-lg w-[100%] overflow-x-auto`}
      >
        {uploadedFiles.map((file, index) => {
          const fileType = file.file_type;
          return (
            <div>
              {fileType.startsWith("image/") ? (
                <div className="relative w-full">
                  <img
                    src={file.file_url}
                    alt="Uploaded"
                    className={`w-12 h-12 border rounded-lg  border-opacity-20 m-4 ${
                      theme === "dark" ? "border-white" : "border-black"
                    }`}
                  />
                  <IoCloseCircle
                    className="absolute top-[-2px] left-[72px] cursor-pointer"
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              ) : (
                <div className="relative">
                  <a
                    href={file.file_url}
                    className={`text-white flex flex-row gap-4  py-2 border  m-3 rounded-lg  border-opacity-40 ${
                      theme === "dark"
                        ? "bg-black-200 border-white"
                        : "bg-white border-black"
                    }`}
                  >
                    <FaFileAlt
                      className="ml-4 w-8 h-8 opacity-80"
                      color={theme === "dark" ? "white" : "black"}
                    />
                    <div>
                      <div
                        className={`text-sm   ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {file.file_name && file.file_name.length > 16
                          ? file.file_name.slice(0, 16) + "..."
                          : file.file_name}
                      </div>
                      <div
                        className={`text-xs  opacity-60 ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {fileType}
                      </div>
                    </div>
                  </a>
                  <IoCloseCircle
                    className="absolute top-[-2px] right-1 cursor-pointer "
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (e) => {
    let convertedValue = inputValue.replace(/\n/g, "\n\n");
    handleUserQuery(convertedValue);
    setInputValue("");
  };

  const handleUserQuery = (content) => {
    if (handleUserQuery.debounced) {
      clearTimeout(handleUserQuery.debounced);
    }

    handleUserQuery.debounced = setTimeout(async () => {
      setIsScrolling(false);

      if (isStreaming) {
        return;
      }

      let payload = { role: "user", content: content };

      if (uploadedFiles && uploadedFiles.length > 0) {
        payload = {
          ...payload,
          uploadedFiles: uploadedFiles,
        };
      }
      await dispatch({
        type: "ADD_TO_CHAT_DISPLAY",
        payload: {
          ...payload,
        },
      });

      await dispatch({
        type: "SET_IS_STREAMING",
        payload: { isStreaming: true },
      });

      await dispatch({
        type: "SET_LOADING",
        payload: { isLoading: true },
      });

      let newChatMsgsForServer = [];

      if (uploadedFiles && uploadedFiles.length > 0) {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: `${uploadedFiles.map(
              (file) => file.file_text
            )}  ${content}`,
          },
        ];
      } else {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: content,
          },
        ];
      }

      await dispatch({
        type: "ADD_TO_SERVER",
        payload: {
          role: "user",
          content: content,
        },
      });

      emitToServer("ai_chat", {
        messages: [...newChatMsgsForServer],
        email : currentUser?.email
      });
      setUploadedFiles([]);
    }, 100);
  };

  const stopStreamingHandler = () => {
    dispatch({
      type: "AI_STREAM_STOPPED",
      payload: {
        role: "assistant",
        content: latestStream.content,
        id: latestStream.id,
      },
    });
  };

  const likeMsgHandler = async (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isLiked: true,
    };
    await dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "liked");
  };
  const dislikeMsgHandler = (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isDisliked: true,
    };
    dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "disliked");
  };

  const updateRows = () => {
    const input = inputRef.current;
    if (input) {
      const numberOfLines = input.value.split("\n").length;
      setRows(numberOfLines === 0 ? 1 : numberOfLines);
    }
  };

  const handleChatHistory = (chat) => {
    if (isStreaming) {
      return;
    }
    navigate(`/chat/${chat.chat_id}`);
  };

  const regenerateHandler = async (content) => {
    chatMsgsToDisplay.pop();
    chatMsgsForServer.pop();

    await dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: chatMsgsToDisplay,
        chatMsgsForServer: chatMsgsForServer,
      },
    });

    if (isStreaming) {
      return;
    }

    await dispatch({
      type: "SET_IS_STREAMING",
      payload: { isStreaming: true },
    });

    await dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true },
    });

    emitToServer("ai_chat", {
      messages: chatMsgsForServer,
      email : currentUser?.email

    });
  };

  const deleteChatHistory = async (chatId) => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat?chat_id=${chatId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Deleted chat successfully");
      await getSavedChatList();
      await newChatHandler();
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to delete chat history");
    }
  };

  const getFilesByEmail = async () => {
    const email =
      currentUser && Object.keys(currentUser).length > 0
        ? currentUser.email
        : "";

    if (email) {
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-files-by-email?user_email=${email}`
        );

        if (response && response.data && response.data.files) {
          setFiles(response?.data?.files);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleSettingsHandler = () => {
    setSettingsOpen(true);
  };
  const getSavedChatList = async () => {
    try {
      const response = await axios.get(
        `${getAPIBaseUrl()}/list-chat?user_email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setChatHistory(response.data?.chat_history);
    } catch (error) {
      console.log("error", error);
    }
  };
  const saveChatHistory = async (
    customMsg = false,
    index = null,
    type = null
  ) => {
    let newChatId = userChatId;
    if (!userChatId) {
      newChatId = uuidv4();
      storeDispatch(setChatId(newChatId));
    }
    if (isStreaming) {
      return;
    }

    if (chatMsgsToDisplay.length === 0) {
      return;
    }

    const isLoggedIn = currentUser && Object.keys(currentUser).length > 0;

    if (!isLoggedIn) {
      return;
    }

    let newChatMsgsToDisplay = chatMsgsToDisplay;

    if (customMsg) {
      newChatMsgsToDisplay[index] = {
        ...newChatMsgsToDisplay[index],
        isLiked: type === "liked" ? true : false,
        isDisliked: type === "disliked" ? true : false,
      };
    }
    let data = {
      chat_id: newChatId,
      conversations: JSON.stringify({
        chat_msgs_for_server: chatMsgsForServer,
        chat_msgs_to_display: customMsg
          ? newChatMsgsToDisplay
          : chatMsgsToDisplay,
      }),
      user_email:
        currentUser && Object.keys(currentUser).length > 0
          ? currentUser.email
          : "",
    };

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email = currentUser.email;
    }

    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${newChatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let title = "";
      let createdAt = "";
      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.title
      ) {
        title = chatDetails.data.chat_history.title;
      } else {
        const response = await axios.post(`${getAPIBaseUrl()}/generate-title`, {
          message: chatMsgsForServer[chatMsgsForServer.length - 1].content,
        });
        title = response.data.title;
      }

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.created_at
      ) {
        createdAt = chatDetails.data.chat_history.created_at;
      } else {
        createdAt = new Date().toISOString();
      }

      data = {
        ...data,
        title: title,
        createdAt: createdAt,
      };
      try {
        await axios.post(`${getAPIBaseUrl()}/save-chat-history`, data);
        getSavedChatList();
      } catch (error) {
        console.log("erro>>", error);
      }
    } catch (error) {
      console.log("error>>");
      navigate("/");
    }
  };

  const newChatHandler = () => {
    if (isStreaming) {
      return;
    }
    const chatId = uuidv4();
    storeDispatch(setChatId(chatId));
    navigate(`/chat/${chatId}`);
  };

  const renameTitleHandler = useCallback(async (chat) => {
    setSelectedChat(chat.chat_id);
    setUpdatedChatTitle(chat.title);

    setTimeout(() => {
      if (chatInputRef.current) {
        chatInputRef.current.focus();
      }
    }, 100);
  }, []);

  const checkPaymentStatus = async () => {
    try {
      const response = await axios.get(
        `${getAPIBaseUrl()}/check-user-payment-status?email=${
          currentUser.email
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data && response.data.is_payment) {
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  };

  const getChatDetailsById = async () => {
    if (!chatId) {
      dispatch({
        type: "UPDATE_MSG_FROM_SESSION",
        payload: {
          chatMsgsToDisplay: [],
          chatMsgsForServer: [],
        },
      });
      return;
    }

    setChatMsgLoading(true);

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email = currentUser.email;
    }
    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${chatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0
      ) {
        await storeDispatch(setChatId(chatDetails.data.chat_history.chat_id));
        const conversations = JSON.parse(
          chatDetails.data.chat_history.conversations
        );

        dispatch({
          type: "RESET_CHAT_DISPLAY",
        });
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: conversations?.chat_msgs_to_display,
            chatMsgsForServer: conversations?.chat_msgs_for_server,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: [],
            chatMsgsForServer: [],
          },
        });
      }
      setChatMsgLoading(false);
    } catch (error) {
      console.log("error", error);
      navigate("/");
      setChatMsgLoading(false);
    }
  };

  useEffect(() => {
    if (isScrolling) {
      return;
    }
    if (scrollContainerRef.current) {
      console.log(
        "scrollContainerRef.current1",
        scrollContainerRef.current.scrollHeight
      );
      scrollContainerRef.current.scrollTop +=
        scrollContainerRef.current.scrollHeight;
    }
  }, [latestStream, chatMsgsToDisplay]);

  useEffect(() => {
    let interval;

    if (!showLoading) {
      setLoadingText(
        loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
      );

      setLoadingDotsDirection("up");
    } else {
      interval = setInterval(() => {
        const numDots = 5;

        let lastNumDotsChars = loadingText.slice(-numDots);

        let periodCount = lastNumDotsChars.split(".").length - 1;

        if (loadingDotsDirection === "up" && periodCount !== numDots) {
          setLoadingText((prevLoadingText) => prevLoadingText + ".");
        } else if (loadingDotsDirection === "down" && periodCount !== 0) {
          setLoadingText((prevLoadingText) => prevLoadingText.slice(0, -1));
        } else if (periodCount === numDots) {
          setLoadingDotsDirection("down");
        } else if (periodCount === 0) {
          setLoadingDotsDirection("up");
        }
      }, 200);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showLoading, loadingText, loadingDotsDirection]);

  useEffect(() => {
    saveChatHistory();
  }, [chatMsgsForServer, isStreaming]);

  useEffect(() => {
    updateRows();
  }, [inputValue]);

  useEffect(() => {
    getChatDetailsById();
  }, [chatId]);

  useEffect(() => {
    const fetchChatList = () => {
      if (currentUser && Object.keys(currentUser).length > 0) {
        getSavedChatList();
      } else {
        setChatHistory([]);
      }
    };

    fetchChatList(); // Call on mount
    getFilesByEmail();

    window.addEventListener("load", fetchChatList);

    return () => {
      window.removeEventListener("load", fetchChatList);
    };
  }, [currentUser]);

  useEffect(() => {
    const getSavedList = async () => {
      setChatHistoryLoading(true);
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/list-chat?user_email=${currentUser.email}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setChatHistory(response.data?.chat_history);
        setChatHistoryLoading(false);
      } catch (error) {
        console.log("error", error);
        setChatHistoryLoading(false);
      }
    };
    getSavedList();
    checkPaymentStatus();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("test", chatInputRef);
      if (
        chatInputRef.current &&
        !chatInputRef.current.contains(event.target)
      ) {
        console.log("test1");

        setSelectedChat("");
        saveUpdatedChatTitle("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedChat]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setExpanded((prevExpanded) => !prevExpanded);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  console.log("chatMsgsToDisplay2", chatMsgsToDisplay);
  return (
    <div className={`bg-${theme === "dark" ? "black-100" : "white"} h-screen`}>
      <div className="grid grid-cols-4 ">
        <div
          className={`col-span-1 h-screen ${
            theme === "dark" ? "bg-black-50" : "bg-grey-100 bg-opacity-10"
          } px-4 pt-4 w-full`}
        >
          <div className="flex flex-row justify-between">
            <div className=" flex flex-row gap-3 w-fit  cursor-pointer rounded-lg h-fit mb-4">
              <div
                className={
                  theme === "dark"
                    ? "text-white flex- flex-row "
                    : "text-black-100 flex- flex-row gap-2"
                }
              >
                <img
                  className=" h-12 w-[150px] "
                  src={theme === "dark" ? ggDarkLogo : ggLogo}
                />
              </div>
              {/* <Dropdown
                trigger={["click"]}
                align={{ offset: [-50, 0] }}
                overlay={
                  <div>
                    <div
                      className={`rounded-lg  w-[150%] ${
                        theme === "dark" ? "bg-slate-50" : "bg-white"
                      }`}
                    >
                      <div
                        className={`p-4 cursor-pointer ${
                          theme === "dark"
                            ? "text-white hover:bg-slate-400"
                            : "text-black-100 hover:bg-neutral-50"
                        }`}
                      >
                        <a href="/privacy-policy" target="_blank">
                          Privacy Policy
                        </a>
                      </div>
                      <div
                        className={`p-4 cursor-pointer ${
                          theme === "dark"
                            ? "text-white hover:bg-slate-400"
                            : "text-black-100 hover:bg-neutral-50"
                        }`}
                      >
                        <a href="/terms-of-service" target="_blank">
                          Terms Of Service
                        </a>
                      </div>
                    </div>
                  </div>
                }
              >
                <GoQuestion
                  color={theme === "dark" ? "white" : "black"}
                  size={18}
                  className="mt-0.5"
                />
              </Dropdown> */}
            </div>
            <div
              className={` px-2 rounded-lg flex flex-row justify-center items-center h-10
                ${
                  isStreaming
                    ? "cursor-not-allowed opacity-50 "
                    : theme === "dark"
                    ? "cursor-pointer  hover:bg-black-200"
                    : "cursor-pointer  hover:bg-neutral-50"
                }
                `}
              onClick={newChatHandler}
            >
              <FaRegEdit
                color={theme === "dark" ? "white" : "black"}
                size={18}
              />
            </div>
          </div>
          {currentUser && Object.keys(currentUser).length > 0 && (
            <div
              className={`flex flex-row ${
                theme === "dark" ? "text-white" : "text-black-100"
              } opacity-80 pb-5 border-b border-white border-opacity-50`}
            >
              <span
                className={`text-${theme === "dark" ? "white" : "black-100"}`}
              >
                Welcome
              </span>
              <span
                className={` ml-2 text-${
                  theme === "dark" ? "white" : "black-100"
                } `}
              >
                {currentUser.name}
              </span>
            </div>
          )}
          <div className=" h-[85vh] overflow-y-auto hide-scrollbar">
            <div className="flex flex-row justify-between my-4">
              <div
                className={`text-xl  ${
                  theme === "dark" ? "text-white" : "text-black-100"
                }`}
              >
                Files
              </div>
              {isFilesExpanded ? (
                <BiChevronUpCircle
                  size={20}
                  color={theme === "dark" ? "white" : "black"}
                  className="cursor-pointer"
                  onClick={() => {
                    setFilesExpanded(false);
                  }}
                />
              ) : (
                <MdOutlineExpandCircleDown
                  size={20}
                  color={theme === "dark" ? "white" : "black"}
                  className="cursor-pointer"
                  onClick={() => {
                    setFilesExpanded(true);
                  }}
                />
              )}
            </div>
            {isFilesExpanded && (
              <div className="">
                {files && files.length > 0 ? (
                  files.map((file) => (
                    <div className="flex flex-row justify-between my-2">
                      <div className="flex flex-row gap-2">
                        <FaFileAlt
                          className=" w-6 h-6 opacity-80"
                          color={theme === "dark" ? "white" : "black"}
                        />

                        <div
                          className={`${
                            theme === "dark" ? "text-white" : "text-black-100"
                          } opacity-70 mb-2 `}
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            wordBreak: "break-all",
                          }}
                        >
                          {file.file_name}
                        </div>
                      </div>
                      <div className="flex flex-row gap-2">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`/chat/${file.chat_id}`);
                          }}
                        >
                          <IoEyeOutline
                            className=" w-6 h-6 opacity-80"
                            color={theme === "dark" ? "white" : "black"}
                          />
                        </div>
                        <a href={file.file_url}>
                          <GoDownload
                            className=" w-6 h-6 opacity-80"
                            color={theme === "dark" ? "white" : "black"}
                          />
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-row justify-left gap-2 mb-4">
                    <ImFilesEmpty
                      color={theme === "dark" ? "white" : "black"}
                      className="opacity-70 w-6 h-6 pr-2"
                    />
                    <div
                      className={`${
                        theme === "dark" ? "text-white" : "text-black-100"
                      } opacity-70 `}
                    >
                      No Files Found
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-row justify-between">
              <div
                className={`text-xl ${
                  theme === "dark" ? "text-white" : "text-black-100"
                }`}
              >
                Chat History
              </div>
              {isChatHistoryExpanded ? (
                <BiChevronUpCircle
                  size={20}
                  color={theme === "dark" ? "white" : "black"}
                  className="cursor-pointer"
                  onClick={() => {
                    setChatHistoryExpanded(false);
                  }}
                />
              ) : (
                <MdOutlineExpandCircleDown
                  size={20}
                  color={theme === "dark" ? "white" : "black"}
                  className="cursor-pointer"
                  onClick={() => {
                    setChatHistoryExpanded(true);
                  }}
                />
              )}
            </div>
            {isChatHistoryExpanded ? (
              chatHistoryLoading ? (
                <div className="">
                  <div
                    className={`${
                      theme === "dark" ? "text-white" : "text-black-100"
                    } text-base mb-2.5  font-bold pt-6`}
                  >
                    Today
                  </div>
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className={`${
                        theme === "dark" ? "bg-slate-300" : "bg-neutral-50"
                      } rounded-md p-2.5 h-10 mb-6 ${
                        expanded ? "w-full" : "w-16"
                      } ${index !== 2 && "mb-1"}`}
                      style={{
                        transition: "width 0.5s ease-out",
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    ></div>
                  ))}
                </div>
              ) : (
                <div className="pt-2">
                  {chatHistory &&
                  chatHistory["today"] &&
                  chatHistory["today"].length > 0 ? (
                    <div className="flex flex-col">
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-base mb-2.5  font-bold pt-4`}
                      >
                        Today
                      </div>
                      {chatHistory["today"].map((chat) => (
                        <div>
                          {selectedChat === chat.chat_id ? (
                            <Input
                              type="text"
                              value={updatedChatTitle}
                              ref={chatInputRef}
                              onChange={(e) =>
                                setUpdatedChatTitle(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  saveUpdatedChatTitle();
                                }
                              }}
                            />
                          ) : (
                            <div
                              className={`flex flex-row justify-between ${
                                theme === "dark"
                                  ? "hover:bg-black-200 "
                                  : "hover:bg-neutral-50"
                              } px-2 py-3 cursor-pointer rounded-lg`}
                              onClick={() => handleChatHistory(chat)}
                            >
                              <div
                                className={`${
                                  theme === "dark"
                                    ? "text-white"
                                    : "text-black-100"
                                } text-sm  opacity-80  `}
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  wordBreak: "break-all",
                                }}
                              >
                                {chat.title ? chat.title : "Untitled"}
                              </div>
                              <Dropdown
                                trigger={["click"]}
                                align={{ offset: [-50, 0] }}
                                overlay={
                                  <div
                                    className={`rounded-lg  w-[150%] ${
                                      theme === "dark"
                                        ? "bg-slate-50"
                                        : "bg-white"
                                    }`}
                                  >
                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } hover:rounded-t-lg`}
                                      onClick={() => {
                                        setShareChatModal(true);
                                        setSelectedChatId(chat.chat_id);
                                      }}
                                    >
                                      <IoShareSocial
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />

                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Share
                                      </div>
                                    </div>
                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } hover:rounded-b-lg`}
                                      onClick={() => renameTitleHandler(chat)}
                                    >
                                      <MdDriveFileRenameOutline
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Rename
                                      </div>
                                    </div>

                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } hover:rounded-b-lg`}
                                      onClick={() => {
                                        deleteChatHistory(chat.chat_id);
                                      }}
                                    >
                                      <RiDeleteBin7Line
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <BiDotsHorizontalRounded
                                  color={theme === "dark" ? "white" : "black"}
                                  size={20}
                                  className="ml-2"
                                />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col mb-5">
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-base mb-2 font-bold`}
                      >
                        Today
                      </div>
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-xs opacity-80`}
                      >
                        No Messages today . Please start a conversation to have
                        chat history
                      </div>
                    </div>
                  )}
                  {chatHistory &&
                  chatHistory["yesterday"] &&
                  chatHistory["yesterday"].length > 0 ? (
                    <div className="flex flex-col">
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-base mb-2.5  font-bold pt-4`}
                      >
                        Yesterday
                      </div>
                      {chatHistory["yesterday"].map((chat) => (
                        <div>
                          {selectedChat === chat.chat_id ? (
                            <Input
                              type="text"
                              value={updatedChatTitle}
                              ref={chatInputRef}
                              onChange={(e) =>
                                setUpdatedChatTitle(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  saveUpdatedChatTitle();
                                }
                              }}
                            />
                          ) : (
                            <div
                              className={`flex flex-row justify-between ${
                                theme === "dark"
                                  ? "hover:bg-black-200 "
                                  : "hover:bg-neutral-50"
                              } px-2 py-3 cursor-pointer rounded-lg`}
                              onClick={() => handleChatHistory(chat)}
                            >
                              <div
                                className={`${
                                  theme === "dark"
                                    ? "text-white"
                                    : "text-black-100"
                                } text-sm  opacity-80  `}
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  wordBreak: "break-all",
                                }}
                              >
                                {chat.title ? chat.title : "Untitled"}
                              </div>
                              <Dropdown
                                trigger={["click"]}
                                align={{ offset: [-50, 0] }}
                                overlay={
                                  <div className="rounded-lg bg-slate-50 w-[150%]">
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-t-lg"
                                      onClick={() => {
                                        setShareChatModal(true);
                                        setSelectedChatTitle(chat.title);
                                      }}
                                    >
                                      <img
                                        src={logoutIcon}
                                        className="w-4 h-4 "
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Share
                                      </div>
                                    </div>
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => renameTitleHandler(chat)}
                                    >
                                      <MdDriveFileRenameOutline
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Rename
                                      </div>
                                    </div>

                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => {
                                        deleteChatHistory(chat.chat_id);
                                      }}
                                    >
                                      <RiDeleteBin7Line
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <BiDotsHorizontalRounded
                                  color={theme === "dark" ? "white" : "black"}
                                  size={20}
                                  className="ml-2"
                                />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {chatHistory &&
                  chatHistory["last_30_days"] &&
                  chatHistory["last_30_days"].length > 0 ? (
                    <div className="flex flex-col">
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-base mb-2.5  font-bold pt-4`}
                      >
                        Last 30 Days
                      </div>
                      {chatHistory["last_30_days"].map((chat) => (
                        <div>
                          {selectedChat === chat.chat_id ? (
                            <Input
                              type="text"
                              value={updatedChatTitle}
                              ref={chatInputRef}
                              onChange={(e) =>
                                setUpdatedChatTitle(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  saveUpdatedChatTitle();
                                }
                              }}
                            />
                          ) : (
                            <div
                              className={`flex flex-row justify-between ${
                                theme === "dark"
                                  ? "hover:bg-black-200 "
                                  : "hover:bg-neutral-50"
                              } px-2 py-3 cursor-pointer rounded-lg`}
                              onClick={() => handleChatHistory(chat)}
                            >
                              <div
                                className={`${
                                  theme === "dark"
                                    ? "text-white "
                                    : "text-black-100"
                                } text-sm  opacity-80  `}
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  wordBreak: "break-all",
                                }}
                              >
                                {chat.title ? chat.title : "Untitled"}
                              </div>
                              <Dropdown
                                trigger={["click"]}
                                align={{ offset: [-50, 0] }}
                                overlay={
                                  <div className="rounded-lg bg-slate-50 w-[150%]">
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-t-lg"
                                      onClick={() => {
                                        setShareChatModal(true);
                                        setSelectedChatTitle(chat.title);
                                      }}
                                    >
                                      <img
                                        src={logoutIcon}
                                        className="w-4 h-4 "
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Share
                                      </div>
                                    </div>
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => renameTitleHandler(chat)}
                                    >
                                      <MdDriveFileRenameOutline
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Rename
                                      </div>
                                    </div>

                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => {
                                        deleteChatHistory(chat.chat_id);
                                      }}
                                    >
                                      <RiDeleteBin7Line
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <BiDotsHorizontalRounded
                                  color={theme === "dark" ? "white" : "black"}
                                  size={20}
                                  className="ml-2"
                                />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {chatHistory &&
                  chatHistory["last_year"] &&
                  chatHistory["last_year"].length > 0 ? (
                    <div className="flex flex-col">
                      <div className="text-white text-base mb-4 text-white font-bold">
                        Last Year
                      </div>
                      {chatHistory["last_year"].map((chat) => (
                        <div>
                          {selectedChat === chat.chat_id ? (
                            <Input
                              type="text"
                              value={updatedChatTitle}
                              ref={chatInputRef}
                              onChange={(e) =>
                                setUpdatedChatTitle(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  saveUpdatedChatTitle();
                                }
                              }}
                            />
                          ) : (
                            <div
                              className="flex flex-row justify-between hover:bg-black-200 px-2 py-3 cursor-pointer rounded-lg"
                              onClick={() => handleChatHistory(chat)}
                            >
                              <div
                                className="text-white text-sm text-white opacity-80  "
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  wordBreak: "break-all",
                                }}
                              >
                                {chat.title ? chat.title : "Untitled"}
                              </div>
                              <Dropdown
                                trigger={["click"]}
                                align={{ offset: [-50, 0] }}
                                overlay={
                                  <div className="rounded-lg bg-slate-50 w-[150%]">
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-t-lg"
                                      onClick={() => {
                                        setShareChatModal(true);
                                        setSelectedChatTitle(chat.title);
                                      }}
                                    >
                                      <img
                                        src={logoutIcon}
                                        className="w-4 h-4 "
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Share
                                      </div>
                                    </div>
                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => renameTitleHandler(chat)}
                                    >
                                      <MdDriveFileRenameOutline
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Rename
                                      </div>
                                    </div>

                                    <div
                                      className="flex flex-row p-3 cursor-pointer hover:bg-slate-400 hover:rounded-b-lg"
                                      onClick={() => {
                                        deleteChatHistory(chat.chat_id);
                                      }}
                                    >
                                      <RiDeleteBin7Line
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing text-white">
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <BiDotsHorizontalRounded
                                  color={theme === "dark" ? "white" : "black"}
                                  size={20}
                                  className="ml-2"
                                />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )
            ) : null}
          </div>
        </div>

        <div
          className={`col-span-3 ${
            theme === "dark" ? "bg-black-100" : "bg-white"
          } flex flex-col   mx-[40px]`}
        >
          {currentUser && Object.keys(currentUser).length > 0 ? (
            <div className="flex flex-row justify-end py-2 ">
              <Dropdown
                trigger={["click"]}
                align={{ offset: [-100, 0] }}
                overlay={
                  <div
                    className={`rounded-lg  w-[150%] ${
                      theme === "dark"
                        ? "bg-black-50 text-white"
                        : "bg-white border text-black"
                    }`}
                  >
                    <div
                      className={`flex flex-row p-3 cursor-pointer hover:rounded-t-lg  cursor-pointer ${
                        theme === "dark"
                          ? "hover:bg-slate-50 "
                          : "hover:bg-neutral-50"
                      }`}
                      onClick={handleSettingsHandler}
                    >
                      <IoMdSettings
                        className="w-4 h-4 "
                        color={theme === "dark" ? "white" : "black"}
                      />
                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing opacity-70">
                        Settings
                      </div>
                    </div>
                    <div
                      className={`flex flex-row p-3 cursor-pointer hover:rounded-t-lg  cursor-pointer ${
                        theme === "dark"
                          ? "hover:bg-slate-50 "
                          : "hover:bg-neutral-50"
                      }`}
                      onClick={() => {
                        navigate("/my-plan");
                      }}
                    >
                      <MdOutlinePayment
                        className="w-4 h-4 "
                        color={theme === "dark" ? "white" : "black"}
                      />
                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing opacity-70">
                        My Plan
                      </div>
                    </div>
                    <div
                      className={`flex flex-row p-3 cursor-pointer hover:rounded-b-lg  cursor-pointer ${
                        theme === "dark"
                          ? "hover:bg-slate-50 "
                          : "hover:bg-neutral-50"
                      }`}
                      onClick={logoutUser}
                    >
                      <AiOutlineLogout
                        className="w-4 h-4 "
                        color={theme === "dark" ? "white" : "black"}
                      />

                      <div className="text-xs font-semibold ml-2 font-sans leading-4 spacing opacity-70">
                        Logout
                      </div>
                    </div>
                  </div>
                }
              >
                <div
                  className={`pl-2 mr-5   flex flex-col justify-center cursor-pointer `}
                >
                  <RxHamburgerMenu
                    className="w-7 h-7 "
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              </Dropdown>
            </div>
          ) : (
            <div></div>
          )}
          {isChatMsgLoading ? (
            <ChatUiSkeleton theme={theme} />
          ) : (
            <div className="mt-8 ">
              <div
                className={`overflow-y-auto hide-scrollbar relative  ${
                  uploadedFiles && uploadedFiles.length > 0
                    ? "h-[72vh]"
                    : "h-[80vh]"
                } ${theme == "dark" ? "bg-black-100" : "bg-white"}`}
                onWheel={() => {
                  setIsScrolling(true);
                }}
                ref={scrollContainerRef}
              >
                {chatMsgsToDisplay && chatMsgsToDisplay.length > 0 ? (
                  chatMsgsToDisplay.map((msg, index) => (
                    <ChatCard
                      message={msg}
                      regenerateHandler={regenerateHandler}
                      likeMsgHandler={() => likeMsgHandler(index)}
                      dislikeMsgHandler={() => dislikeMsgHandler(index)}
                      isVisible={index === chatMsgsToDisplay.length - 1}
                      isStreaming={isStreaming}
                      uploadedFiles={msg.uploadedFiles}
                      theme={theme}
                    />
                  ))
                ) : (
                  <div className="text-white  h-full flex flex-row items-end pb-8">
                    {/* <div className="grid grid-cols-2 w-full gap-4">
                {prompts.map((prompt) => (
                  <div
                    className="border border-white rounded-lg px-3 py-2 cursor-pointer hover:bg-black-200"
                    onClick={() => handleUserQuery(prompt.description)}
                  >
                    <div className="text-white">{prompt.title}</div>
                    <div className="text-white opacity-50 text-sm">
                      {prompt.helperText}
                    </div>
                  </div>
                ))}
              </div> */}
                  </div>
                )}
                {showLoading ? (
                  <ChatCard
                    message={{
                      role: "assistant",
                      content: loadingText,
                    }}
                    isVisible={false}
                    isStreaming={isStreaming}
                    theme={theme}
                  />
                ) : latestStream && latestStream.content ? (
                  <ChatCard
                    message={{
                      role: "assitant",
                      content: latestStream.content,
                    }}
                    isVisible={false}
                    isStreaming={isStreaming}
                    theme={theme}
                  />
                ) : null}
              </div>

              <div className="absolute bottom-[100px] left-[60%] z-50 ">
                <div
                  className={`rounded-full  p-2 cursor-pointer w-8 h-8 hover:bg-black-50  ${
                    theme === "dark" ? "bg-grey-50" : "bg-neutral-50 "
                  }`}
                  onClick={scrollToBottom}
                >
                  <MdArrowDownward
                    className="w-4 h-4 "
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              </div>
              <div
                className={` w-[70%] mr-10 flex flex-col justify-end  absolute bottom-3`}
              >
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <div>{renderUploadedFile()}</div>
                )}{" "}
                <div className="w-full flex items-center justify-between">
                  <form
                    autoComplete="off"
                    action=""
                    onSubmit={(e) => e.preventDefault()}
                    className="w-full"
                  >
                    <textarea
                      ref={inputRef}
                      id="chat-input"
                      name="chat-input"
                      className={`px-9 py-3  w-full ${
                        uploadedFiles && uploadedFiles.length > 0
                          ? "rounded-b-lg"
                          : "rounded-lg"
                      } ${
                        theme === "dark"
                          ? "bg-black-200 text-white"
                          : "bg-neutral-50 text-black"
                      }  w-full focus:outline-none ${
                        rows === 1
                          ? "h-12 overflow-y-hidden"
                          : rows === 2
                          ? "h-16"
                          : rows === 3
                          ? "h-20"
                          : rows === 4
                          ? "h-[96px]"
                          : rows === 5
                          ? "h-[112px]"
                          : "h-[128px]"
                      }`}
                      value={inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputValue(value);
                      }}
                      onKeyDown={(e) => {
                        if (e.shiftKey && e.key === "Enter") {
                          if (rows < 6) {
                            setRows(rows + 1);
                          }
                          setInputValue(inputValue);
                        } else if (e.key === "Enter" && inputValue) {
                          e.preventDefault();

                          handleKeyPress();
                          setRows(1);
                        }
                      }}
                      placeholder="Message GG AI"
                    />
                    <div>
                      <div
                        className={`absolute left-0 w-6 h-4  text-white ml-2 mr-3 cursor-pointer
                   bottom-8
                    `}
                        onClick={() => {
                          if (!isUploadFileLoading) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        {isUploadFileLoading ? (
                          <Loader className="w-4 h-4" />
                        ) : (
                          <IoIosAttach
                            color={theme === "dark" ? "white" : "black"}
                            size={24}
                            className="mt-1"
                          />
                        )}
                      </div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        multiple={true}
                        onChange={uploadFileHandler}
                        style={{ display: "none" }}
                      />
                      <div
                        class={`absolute bottom-4 left-[95%] w-8 h-8 flex items-center  cursor-pointer `}
                      >
                        {isStreaming ? (
                          <div onClick={stopStreamingHandler}>
                            <img src={stopStreamIcon} className=" mr-1 pb-1" />
                          </div>
                        ) : (
                          <div
                            className="rounded-md  bg-purple-200 p-2 cursor-poiner"
                            onClick={(e) => {
                              e.stopPropagation();

                              if (inputValue) {
                                e.preventDefault();

                                handleKeyPress();
                              }
                            }}
                          >
                            <LuSend className="w-4 h-4 text-white" />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={isLoginModal}
        handleClose={() => {
          setLoginModal(!isLoginModal);
          setLoginErrorMessage(false);
          setSignupErrorMessage(false);
          setAuthLoading(false);
        }}
        theme={theme}
      >
        <Login
          loginHandler={loginHandler}
          navigateToSignup={navigateToSignup}
          loading={authLoading}
          loginErrorMessage={loginErrorMessage}
          handleSuccess={handleSuccess}
          theme={theme}
        />
      </CustomModal>
      <CustomModal
        isOpen={isSignUpModal}
        handleClose={() => {
          setSignUpModal(!isSignUpModal);
          setLoginErrorMessage(false);
          setSignupErrorMessage(false);
          setAuthLoading(false);
        }}
        theme={theme}
      >
        <Signup
          signupHandler={signupHandler}
          navigateToLogin={navigateToLogin}
          loading={authLoading}
          signupErrorMessage={signupErrorMessage}
          handleSuccess={handleSuccess}
          theme={theme}
        />
      </CustomModal>
      <CustomModal
        isOpen={isSettingsOpen}
        handleClose={() => {
          setSettingsOpen(!isSettingsOpen);
        }}
        theme={theme}
      >
        <Settings
          theme={theme}
          selectedTheme={selectedTheme}
          setTheme={setTheme}
          deleteUserHandler={deleteUserHandler}
          deleteAllChatHandler={deleteAllChatHandler}
          files={files}
          handleClose={() => {
            setSettingsOpen(!isSettingsOpen);
          }}
        />
      </CustomModal>
      <CustomModal
        isOpen={isShareChatModal}
        handleClose={() => {
          setShareChatModal(false);
        }}
        theme={theme}
      >
        <div className="flex flex-col px-4 py-4 ">
          <div className="flex flex-row justify-between">
            <div
              className={`${
                theme === "dark" ? "text-white" : "text-black-100"
              } font-bold text-center text-xl`}
            >
              Share Chat
            </div>
            <IoCloseSharp
              color={theme === "dark" ? "white" : "black"}
              size={24}
              className="cursor-pointer"
              onClick={() => {
                setShareChatModal(false);
              }}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div
              className={` p-2 rounded-lg mt-8 ${
                theme === "dark"
                  ? "border border-white"
                  : "border border-black-100"
              }`}
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
              }}
            >
              {window.location.href}
            </div>
            <div
              className="bg-white text-xl flex flex-row gap-2 text-black-100 w-fit p-2 h-10 mt-8 rounded-lg cursor-pointer"
              onClick={async () => {
                setCopyLoading(true);
                await axios.post(`${getAPIBaseUrl()}/make-chat-public`, {
                  chat_id: selectedChatId,
                });

                copy(window.location.href);
                setCopyLoading(false);
              }}
            >
              {isCopyLoading ? (
                <div className="flex flex-row justify-center w-[60px] h-10">
                  <Loader />
                </div>
              ) : (
                <div className="flex flex-row justify-between w-[60px] h-10">
                  <div className="flex flex-row justify-between mr-1">Copy</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-6 mt-8 justify-center">
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://twitter.com/intent/tweet?text=${selectedChatTitle}&url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareTwitter size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.reddit.com/submit?url=${window.location.href}&title=${selectedChatTitle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareReddit size={20} />
            </a>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default DesktopChatScreen;
