import { getAPIBaseUrl } from "./constants";

export const fetchJson = async (api_relative_path, postObj) => {
  const req_url = getAPIBaseUrl() + api_relative_path;
  const headers = { 'Content-Type': 'application/json' };
  return fetch(req_url, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(postObj),
  })
  .then(response => response.json())
}

export const fetchJsonExternal = async (absolute_url, postObj) => {
  return fetch(absolute_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postObj),
  })
  .then(response => response.json())
}


export const fetchData = async (api_relative_path) => {
  const req_url = getAPIBaseUrl() + api_relative_path;
  const headers = { 'Content-Type': 'application/json' };
  return fetch(req_url, {
    method: 'GET',
    headers: headers,
    credentials: 'include',
  })
  .then(response => response.json())
}

