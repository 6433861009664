const Input = (props) => {
  return (
    <input
      class={`w-full h-12 mt-2 font-sans  text-sm font-medium rounded-lg focus:outline-none    p-3  ${
        props.isError && "border-red-100"
      } 
       ${
         props.theme === "dark"
           ? "bg-black-800 text-white opacity-90 border border-grey-900 focus:border focus:border-2 border-white"
           : "bg-neutral-50 text-black opacity-90 border border-slate-50 focus:border focus:border-2  border-grey-900"
       }
      `}
      {...props}
    />
  );
};

export default Input;
