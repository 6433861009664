import React, { useEffect, useState } from "react";

const ChatUiSkeleton = ({ theme = "dark" }) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setExpanded((prevExpanded) => !prevExpanded);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full mt-5">
      <div className="flex flex-row justify-end mb-8">
        <div
          className={`h-10 ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-[50%]" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-start mb-8">
        <div
          className={`h-[100px] w-full  ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-full" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-end mb-8">
        <div
          className={`h-10 ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-[50%]" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-start mb-8">
        <div
          className={`h-[100px] w-full  ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-full" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-end mb-8">
        <div
          className={`h-10 ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-[50%]" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-start mb-8">
        <div
          className={`h-[100px] w-full  ${
            theme === "dark" ? "bg-black-200" : "bg-neutral-50"
          } rounded-lg ${expanded ? "w-full" : "w-[30%]"}`}
          style={{
            transition: "width 0.5s ease-out",
            transitionDelay: `${1 * 0.1}s`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ChatUiSkeleton;
