import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="m-4">
      <h2>Payment Successful!</h2>
      <p>Redirecting you to the homepage...</p>
    </div>
  );
};

export default SuccessPage