import React from "react";
import { BUTTON_VARIANTS, Button } from "../components/Button";
import { useNavigate } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className={`w-full h-full`}>
      <div
        className={`
      pt-7  bg-black-100
      `}
      >
        <div>
          <div
            className={`pt-8 text-white opacity-90 font-sans text-2xl flex flex-row justify-center`}
          >
            Terms Of Service
          </div>
          <div className="text-center flex flex-row justify-center text-white opacity-60 pb-8 text-xs">
            Last Updated: March, 2024
          </div>
          <div className="flex flex-row justify-center">
            <div className={`overflow-y-auto h-[85vh] w-[80%] `}>
              <div>
                <div>
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    1. Acceptance of Terms
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 opacity-70">
                    By accessing or using the services provided by Generative
                    Genius LLC, a Nevada limited liability company (“Company”,
                    “we”, “us”, or “our”), through our website and online
                    platform at https://ai.generativegeniuses.com/chat
                    (collectively, “Services”), you acknowledge that you have
                    read, understood, and agree to be bound by these Terms of
                    Service (“Terms”). These Terms apply to your access and use
                    of the Services and any information, text, graphics, or
                    other materials uploaded, downloaded, or appearing on the
                    Services (collectively referred to as “Content”). You affirm
                    that you are entering into this agreement on your own
                    behalf. Your access to and use of the Services is
                    conditioned on your acceptance of and compliance with these
                    Terms.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    2. User Eligibility
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1 opacity-70">
                    By using the Services, you represent and warrant that you:
                  </div>
                  <ol className="pt-6 ml-4 text-white text-[15px] list-decimal list-inside opacity-70">
                    <li className="pb-2 ">
                      Have read and understand the Terms;
                    </li>
                    <li className="pb-2">
                      Are of legal age to enter into a binding agreement;
                    </li>
                    <li className="pb-2">
                      Accept the Terms and agree that you are legally bound by
                      these terms and the Privacy Policy.
                    </li>
                  </ol>
                  <div className="mt-4 font-sans text-white opacity-70 text-[15px]">
                    If you do not agree to the Terms, do not use and immediately
                    cease use of the Services.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white  text-[18px] mb-3">
                    3. Description of Services
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 mb-3 opacity-70">
                    3.1 The Company provides data management and storage of
                    healthcare and financial information.
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 mb-3 opacity-70">
                    3.2 From time to time, the Company may use artificial
                    intelligence tools and functions (“AI Functions”) as part of
                    the Service. Namely, the Company may use third party
                    artificial intelligence services, including, but not limited
                    to, Open AI’s Chat GPT platform as part of the Service.
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 mb-3 opacity-70">
                    3.3 This section of the Terms applies to AI Functions where
                    you provide information (“Input”) and receive output
                    generated and returned by the Al Functions based on the
                    Input (“Output”).
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 ml-4 mb-3 opacity-70">
                    3.3(a): When you use such AI Functions, as between the
                    parties and to the extent permitted by applicable law,
                    Inputs are deemed to be Content and you are authorized to
                    use Outputs subject to these Terms. You are solely
                    responsible for your Input, including compliance with
                    applicable laws and these Terms. Other users providing
                    similar Input to Al Functions may receive the same or
                    similar Output.
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 ml-4 mb-3 opacity-70">
                    3.3(b): YOU UNDERSTAND AND AGREE THAT ANY INFORMATION
                    OBTAINED THROUGH USING AI FUNCTIONS IS AT YOUR SOLE RISK. DO
                    NOT RELY ON FACTUAL ASSERTIONS IN OUTPUT WITHOUT INDEPENDENT
                    FACT- CHECKING. DO NOT RELY ON OUTPUT WITHOUT INDEPENDENT
                    REVIEW OF FUNCTIONALITY AND SUITABILITY FOR YOUR NEEDS. NO
                    SUCH INFORMATION, SUGGESTIONS, OR OUTPUT OBTAINED BY YOU
                    FROM COMPANY OR THROUGH AI FUNCTIONS SHALL CREATE ANY
                    WARRANTY NOT EXPRESSLY MADE HEREIN.
                  </div>
                  <div className="font-sans text-white  text-[15px] pt-1 ml-4 opacity-70">
                    3.3(c): You may not use AI Functions or Output: (1) to
                    develop foundation models or other large-scale models that
                    compete with the Company or the Company’s AI Functions; (2)
                    to mislead any person that Output from the Service was
                    solely human-generated; (3) in a manner that violates any
                    technical documentation, usage guidelines, or parameters;
                    (4) to make automated decisions that may have a detrimental
                    impact on individual rights without appropriate human
                    supervision; or (5) in a manner that infringes, violates, or
                    misappropriates any of our rights or the rights of any third
                    party.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    4. Limitations on Use
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    You are not permitted to use the Content or Services for any
                    illegal or unauthorized purpose. You agree not to reproduce,
                    duplicate, copy, sell, resell, or exploit any portion of or
                    access to the Services other than for your personal use,
                    without the express written permission by the Company.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    5. Data Privacy and Security
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    The Company is committed to protecting the privacy and
                    security of the data you share with us. We do not sell data,
                    however, and the rest of this Privacy Policy provides more
                    in-depth information on our privacy practices. We may engage
                    third-party auditors or reviewers to periodically assess our
                    compliance with the Terms and Privacy Policy and applicable
                    data privacy regulations.
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-3">
                    The Services are currently a beta version. In consideration
                    of your participation in the beta testing program, we
                    caution against any user disclosure of any sensitive
                    information, including persona health information, personal
                    identifiable information, financial, or other sensitive
                    data.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    6. User Conduct
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    You are responsible for all your activitiesin connection
                    with the Services. Any fraudulent, abusive, or otherwise
                    illegal activity may be grounds for termination of your
                    right to access or use the Services.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    7. Modifications to the Terms
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    The Company reserves the right, at its sole discretion, to
                    modify or replace any part of these Terms at any time. It is
                    your responsibility to check these Terms periodically for
                    changes.
                  </span>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    8. Termination
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    The Company may terminate your access to all or any part of
                    the Services at any time, with or without cause, with or
                    without notice, effective immediately.
                  </div>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    9. Disclaimer of Warranties
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    THE SERVICES ARE PROVIDED “AS IS”. THE COMPANY AND ITS
                    SUPPLIERS AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES OF
                    ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                    THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, AND NON- INFRINGEMENT.
                  </span>
                </div>
                <div className="mt-8">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    10. Limitation of Liability
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    IN NO EVENT WILL THE COMPANY, OR ITS SUPPLIERS OR LICENSORS,
                    BE LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS
                    AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY,
                    OR OTHER LEGAL OR EQUITABLE THEORY FOR: (I) ANY SPECIAL,
                    INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL
                    DAMAGES; (II) THE COST OF PROCUREMENT FOR SUBSTITUTE
                    PRODUCTS OR SERVICES; (III) FOR INTERRUPTION OF USE OR LOSS
                    OR CORRUPTION OF DATA. IN NO EVENT WILL THE COMPANY’S
                    AGGREGATE LIABILITY TO YOU ARISING FROM OR RELATED TO YOUR
                    USE OF THE SERVICES EXCEED THE FEES REQUIRED FOR YOUR ACCESS
                    TO THE SERVICES OR $1.00, WHICHEVER IS GREATER, THE
                    LIMITATIONS IN THIS PARAGRAPH ARE REASONABLE AND
                    APPROPRIATE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE
                    LIMITATION OR EXCLUSION OF LIABILITY FOR SPECIAL, INDIRECT,
                    INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES,
                    SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                  </div>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    11. General Representation and Warranty
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] ">
                    You represent and warrant that (i) your use of the Services
                    will be in strict accordance with the Company Privacy
                    Policy, with these Terms, and with all applicable laws and
                    regulations (including without limitation any local laws or
                    regulations in your country, state, city, or other
                    governmental area, regarding online conduct and acceptable
                    content, and including all applicable laws regarding the
                    transmission of technical data exported from the United
                    States or the country in which you reside).
                  </span>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    12. Indemnification
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    You agree to indemnify and hold harmless the Company, its
                    contractors, and its licensors, and their respective
                    directors, officers, employees, and agents from and against
                    any and all claims and expenses, including attorneys' fees,
                    arising out of your use of the Services, including but not
                    limited to your violation of these Terms.
                  </span>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    13. Miscellaneous
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    These Terms constitute the entire agreement between the
                    Company and you concerning the subject matter hereof, and
                    they may only be modified by a written amendment signed by
                    an authorized executive of the Company or by the posting by
                    the Company of a revised version.
                  </span>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    14. Fees and Payment
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1 mb-3">
                    14.1 Payment Processing: Payments for our Services are
                    processed securely through a third-party online payment
                    gateway. This system employs advanced encryption and
                    security measures to ensure the safe transmission and
                    storage of your financial information. While the Company
                    utilizes a payment processing platform known for its
                    reliability and security,{" "}
                    <span className="font-bold">
                      PCI DSS Level 1 certification
                    </span>
                    .
                  </div>
                  <div className="font-sans text-white opacity-70 text-[15px] pt-1">
                    14.2 Changes to Fees and Payment Terms: The Company reserves
                    the right to modify the fees charged for its Services or to
                    introduce new fees at any time, subject to providing users
                    with notice of such changes in accordance with these Terms.
                    Any changes to our fee structure or payment terms will be
                    communicated to you prior to becoming effective, allowing
                    you to make informed decisions about your continued use of
                    our Services.
                  </div>
                </div>
                <div className="mt-8 mb-10">
                  <div className="font-sans text-white opacity-90 text-[18px] mb-3">
                    15. Special Note for California Users
                  </div>
                  <span className="font-sans text-white opacity-70 text-[15px] pt-1">
                    Under California Civil Code Section 1789.3, users of the
                    Services from California are entitled to the following
                    specific consumer rights notice: The Complaint Assistance
                    Unit of the Division of Consumer Services of the California
                    Department of Consumer Affairs may be contacted in writing
                    at 1625 North Market Blvd., Suite N 112, Sacramento, CA
                    95834, or by telephone at (916) 445- 1254 or (800) 952-5210.
                    You may contact us via email at
                  </span>
                  <span className="font-bold text-white ml-2">
                    [Andrew.Hancock@GenerativeGeniuses.com].
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
