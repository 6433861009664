import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAPIBaseUrl } from "../../utility/constants";

const MyPlan = () => {
  const theme = localStorage.getItem("theme") || "light";
  const { currentUser } = useSelector((state) => state.auth);

  const [billingInfo, setBillingInfo] = useState({});
  const [invoices, setInvoices] = useState([]);

  const getUserBillingInfo = async () => {
    try {
      const email =
        currentUser && Object.keys(currentUser).length > 0
          ? currentUser.email
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-billing-info?user_email=${email}`
        );
        const { billing_address, customer_name, invoices, payment_method } =
          response.data;

        setBillingInfo({ billing_address, customer_name, payment_method });
        setInvoices(invoices);
      } else {
        setBillingInfo({});
        setInvoices([]);
      }
    } catch (error) {
      console.log("error>>", error);
      setBillingInfo({});
      setInvoices([]);
    }
  };

  useEffect(() => {
    getUserBillingInfo();
  }, []);
  return (
    <div
      className={`min-h-screen flex items-center justify-center  
        ${
          theme === "dark"
            ? "bg-black-200 text-white"
            : "bg-neutral-50 text-black-100"
        }`}
    >
      <div className={`max-w-2xl w-full bg-black p-8 rounded-lg shadow-md border  border-opacity-20
        ${theme === "dark" ? "border-white" : "border-black-100"}
        `}>
        <h2 className="text-2xl font-bold mb-4">
          Manage your GG AI billing settings
        </h2>
        <a href="/" className="text-indigo-600 mb-6 inline-block">
          &larr; Return to GG AI , LLC
        </a>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Payment Method</h3>
          <div className="flex items-center justify-between">
            <span>
              {billingInfo.payment_method?.card.display_brand.toUpperCase()}{" "}
              **** {billingInfo.payment_method?.card.last4}
            </span>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Billing Information</h3>
          <div className="border border-gray-700 p-4 rounded">
            <p className="font-semibold">Name</p>
            <p>{billingInfo.customer_name || "N/A"}</p>

            <p className="font-semibold mt-4">Billing address</p>
            <p>{billingInfo.billing_address?.line1 || "N/A"}</p>

          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Invoice History</h3>

          <div className="border border-gray-700 p-4 rounded">
            {invoices.map((invoice, index) => (
              <div className="flex justify-between mb-2" key={index}>
                <span>
                  {new Date(invoice.date * 1000).toLocaleDateString()}
                </span>
                <span className="">
                  {invoice.status.charAt(0).toUpperCase() +
                    invoice.status.slice(1)}
                </span>
                <span>US${(invoice.amount / 100).toFixed(2)}</span>
                <a
                  href={invoice.pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-600"
                >
                  View Invoice
                </a>
                <span>GG AI Plus Subscription</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPlan;
