import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { getAPIBaseUrl } from "../../utility/constants";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { useEffect } from "react";

const MSLoginRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  const getUserDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get("code");

    if (authCode) {
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/ms-login?code=${authCode}`
        );
        if (response && response.data) {
          toast.success("Logged in successfully");
          storeDispatch(setUserDetails(response?.data?.user));
          if (response.data.code === "1") {
            navigate("/");
          } else {
            navigate("/billing");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [navigate]);

  return <div>Logging in...</div>;
};

export default MSLoginRedirect;
