import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import ChatScreen from "./pages/ChatScreen";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MSLoginRedirect from "./pages/auth/MSLoginRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsofService";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Billing from "./pages/Payment/Billing";
import MyPlan from "./pages/Payment/MyPlan"
import SuccessPage from "./pages/Payment/SuccessPage";

const googleClientId =
  "454713927448-fpd2ptmgdkl2uhsdfduats3lc78gofkb.apps.googleusercontent.com";


const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/login", element: <Login /> },
    { path: "/create-account", element: <Signup /> },
    {
      path: "/billing",
      element: (
       <Billing />
      ),
    },
    { path: "/", element: <ChatScreen /> },
    { path: "/chat/:chatId", element: <ChatScreen /> },
    { path: "/my-plan", element: <MyPlan /> },
    { path: "/auth-callback", element: <MSLoginRedirect /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/terms-of-service", element: <TermsOfService /> },
    { path: "/success", element: <SuccessPage /> },

  ]);
  return routes;
};

const { store, persistor } = configureStore();

function App() {
  return (
    <div className="h-screen">
      <GoogleOAuthProvider clientId={googleClientId}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <AppRoutes />
          </PersistGate>
        </Provider>
        <ToastContainer position="top-right" autoClose={2000} />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
