import io from 'socket.io-client';
import { getAPIBaseUrl } from "./constants";

let socket = null;
let eventListeners = {};

export const connectSocket = () => {
  if (!socket) {
    socket = io(getAPIBaseUrl(), {
      withCredentials: true,
      transports: ['websocket'],  
      secure: true,        
    });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    Object.keys(eventListeners).forEach((eventName) => {
      socket.off(eventName, eventListeners[eventName]);
    });

    socket.disconnect();
    socket = null;
    eventListeners = {};
  }
};

export const emitToServer = (eventName, data) => {
  if (socket) {
    socket.emit(eventName , data);
  }
};

export const addEventListener = (eventName, callback) => {
  if (socket) {
    eventListeners[eventName] = callback;
    socket.on(eventName, callback);
  }
};

export const removeEventListener = (eventName) => {
  if (socket && eventListeners[eventName]) {
    socket.off(eventName, eventListeners[eventName]);
    delete eventListeners[eventName];
  }
};

export const removeAllEventListener = () => {
  if (socket) {
    socket.removeAllListeners();
  }
};

