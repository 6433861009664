import React, { useEffect, useState } from "react";
import ggLogo from "../../assets/gglogo.png";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.png";
import microsoftIcon from "../../assets/microsoft.png";
import { useGoogleLogin } from "@react-oauth/google";
import { setUserDetails } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { LuSun } from "react-icons/lu";
import { FaMobile, FaMoon } from "react-icons/fa";
import ggDarkLogo from "../../assets/gglogo-dark.png";

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authUrl, setAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setSignupErrorMessage] = useState("");
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyAddress, setCompanyAdress] = useState("");
  const [companyContactNumber, setCompanyContactNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [isPrivacyChecked, setPrivacyChecked] = useState(false);

  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const signupHandler = async () => {
    setLoading(true);
    setIsSubmitted(true);

    if (
      !isPrivacyChecked ||
      !firstName ||
      !lastName ||
      !email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
      password !== confirmPassword ||
      !passwordStrength.minLength ||
      !passwordStrength.hasUppercase ||
      !passwordStrength.hasLowercase ||
      !passwordStrength.hasNumber ||
      !passwordStrength.hasSpecialChar ||
      (isEnterprise && (!companyName || !companyAddress))
    ) {
      setLoading(false);
      return;
    }
    let data = {
      email,
      password,
      name: `${firstName} ${lastName}`,
      phoneNumber,
      isEnterprise: false,
    };

    if (isEnterprise) {
      data = {
        ...data,
        isEnterprise: true,
        companyName: companyName,
        companyWebsite: companyWebsite ? companyWebsite : "",
        companyAddress: companyAddress,
        companyContactNumber: companyContactNumber ? companyContactNumber : "",
        jobTitle: jobTitle ? jobTitle : "",
      };
    }
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setLoading(false);
      setSignupErrorMessage("");
      toast.success("User account created successfully");
      navigate("/billing");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          toast.success("Logged in successfully");
          storeDispatch(setUserDetails(response?.data?.user));
          if (response.data.code === "1") {
            navigate("/");
          } else {
            navigate("/billing");
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 5000 });
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleSuccess(tokenResponse),
  });

  const msLoginHandler = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);

    const newStrength = {
      minLength: passwordValue.length >= 12,
      hasUppercase: /[A-Z]/.test(passwordValue),
      hasLowercase: /[a-z]/.test(passwordValue),
      hasNumber: /[0-9]/.test(passwordValue),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue),
    };

    setPasswordStrength(newStrength);
  };

  useEffect(() => {
    const fetchAuthUrl = async () => {
      const response = await axios.get(`${getAPIBaseUrl()}/ms-auth-url`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAuthUrl(response.data.auth_url);
    };
    fetchAuthUrl();
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const isPasswordValid =
    passwordStrength.minLength &&
    passwordStrength.hasUppercase &&
    passwordStrength.hasLowercase &&
    passwordStrength.hasNumber &&
    passwordStrength.hasSpecialChar;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 dark:text-gray-100">
      <div className="flex flex-row justify-end ">
        <button
          onClick={toggleTheme}
          className=" text-gray-800 w-12 m-3 flex flex-row justify-center p-3 dark:text-gray-200 bg-gray-200 dark:bg-gray-800 rounded-md focus:outline-none"
        >
          {theme === "dark" ? <LuSun /> : <FaMoon />}
        </button>
      </div>
      <div className=" flex flex-col justify-center items-center  p-4">
        <div className="w-full max-w-2xl p-8 space-y-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-row  justify-center">
            <img
              className=" h-14 w-1/2 "
              src={theme === "dark" ? ggDarkLogo : ggLogo}
            />
          </div>
          <div className="text-sm font-medium text-center text-gray-500 rounded-lg grid grid-cols-2  pb-5">
            <div
              className=" w-full h-12  flex flex-row justify-center items-center text-gray-900 bg-white dark:bg-gray-800 cursor-pointer  hover:bg-neutral-1000 dark:text-gray-100 border-y border-l border-gray-300 dark:border-gray-700 rounded-s-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none"
              aria-current="page"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </div>
            <div className=" w-full h-12  flex flex-row justify-center items-center text-gray-900 bg-neutral-1000 dark:bg-gray-700 cursor-pointer  hover:bg-neutral-1000 dark:text-gray-100 border border-gray-300 dark:border-gray-700 rounded-r-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none">
              Sign up
            </div>
          </div>

          <div className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm">
              <div className="mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="first-name" className="sr-only">
                      First Name
                    </label>
                    <input
                      type="text"
                      autoComplete="name"
                      required
                      className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="First Name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                    />
                    {isSubmitted && !firstName && (
                      <span className="text-red-100 text-[13px]">
                        Please enter first name
                      </span>
                    )}
                  </div>
                  <div>
                    <label htmlFor="last-name" className="sr-only">
                      Last Name
                    </label>
                    <input
                      type="text"
                      autoComplete="name"
                      required
                      className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Last Name"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      value={lastName}
                    />
                    {isSubmitted && !lastName && (
                      <span className="text-red-100 text-[13px]">
                        Please enter last name
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
                {isSubmitted &&
                  (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && (
                    <span className="text-red-100 text-[13px] ">
                      Please enter a valid email
                    </span>
                  )}
              </div>
              <div className="pt-6">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  value={password}
                />
                {isSubmitted && (
                  <div className="mt-2">
                    <p
                      className={`text-[13px] ${
                        passwordStrength.minLength
                          ? "text-green-200"
                          : "text-red-100"
                      }`}
                    >
                      {passwordStrength.minLength
                        ? "✔️ At least 12 characters"
                        : "❌ At least 12 characters"}
                    </p>
                    <p
                      className={`text-[13px] ${
                        passwordStrength.hasUppercase
                          ? "text-green-200"
                          : "text-red-100"
                      }`}
                    >
                      {passwordStrength.hasUppercase
                        ? "✔️ At least one uppercase letter"
                        : "❌ At least one uppercase letter"}
                    </p>
                    <p
                      className={`text-[13px] ${
                        passwordStrength.hasLowercase
                          ? "text-green-200"
                          : "text-red-100"
                      }`}
                    >
                      {passwordStrength.hasLowercase
                        ? "✔️ At least one lowercase letter"
                        : "❌ At least one lowercase letter"}
                    </p>
                    <p
                      className={`text-[13px] ${
                        passwordStrength.hasNumber
                          ? "text-green-200"
                          : "text-red-100"
                      }`}
                    >
                      {passwordStrength.hasNumber
                        ? "✔️ At least one number"
                        : "❌ At least one number"}
                    </p>
                    <p
                      className={`text-[13px] ${
                        passwordStrength.hasSpecialChar
                          ? "text-green-200"
                          : "text-red-100"
                      }`}
                    >
                      {passwordStrength.hasSpecialChar
                        ? "✔️ At least one special character"
                        : "❌ At least one special character"}
                    </p>
                  </div>
                )}
              </div>
              <div className="pt-6">
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                />
                {isSubmitted && password !== confirmPassword && (
                  <span className="text-red-100 text-[13px] ">
                    Password doesnot match
                  </span>
                )}
              </div>
              <div className="pt-6">
                <label htmlFor="phone-number" className="sr-only">
                  Phone Number
                </label>
                <input
                  type="number"
                  autoComplete="name"
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Phone Number (Optional)"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                />
              </div>
              <div className="flex flex-row mt-6 gap-4">
                <div class="flex items-center mb-4">
                  <input
                    type="checkbox"
                    value={isEnterprise}
                    class="w-4 h-4  mt-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) => {
                      setIsEnterprise(e.target.checked);
                    }}
                  />
                  <label
                    for="default-radio-1"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-0.5"
                  >
                    Are you creating account on behalf of enterprise
                  </label>
                </div>
              </div>
              {isEnterprise && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="pt-6">
                      <label htmlFor="phone-number" className="sr-only">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Company Name"
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        value={companyName}
                      />
                      {isSubmitted && isEnterprise && !companyName && (
                        <span className="text-red-100 text-[13px] ">
                          Please enter company name
                        </span>
                      )}
                    </div>
                    <div className="pt-6">
                      <label htmlFor="phone-number" className="sr-only">
                        Company Website
                      </label>
                      <input
                        type="text"
                        autoComplete="name"
                        className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Company Website (Optional)"
                        onChange={(e) => {
                          setCompanyWebsite(e.target.value);
                        }}
                        value={companyWebsite}
                      />
                    </div>
                  </div>
                  <div className="pt-6">
                    <label htmlFor="phone-number" className="sr-only">
                      Company Address
                    </label>
                    <input
                      type="text"
                      className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Company Address"
                      onChange={(e) => {
                        setCompanyAdress(e.target.value);
                      }}
                      value={companyAddress}
                    />
                    {isSubmitted && isEnterprise && !companyAddress && (
                      <span className="text-red-100 text-[13px] ">
                        Please enter company address
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-6 ">
                    <div className="">
                      <label htmlFor="phone-number" className="sr-only">
                        Company Phone Number
                      </label>
                      <input
                        type="number"
                        autoComplete="name"
                        className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Company Phone Number (Optional)"
                        onChange={(e) => {
                          setCompanyContactNumber(e.target.value);
                        }}
                        value={companyContactNumber}
                      />
                    </div>
                    <div className="">
                      <label htmlFor="phone-number" className="sr-only">
                        Job Title
                      </label>
                      <input
                        type="text"
                        autoComplete="name"
                        className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Job Title (Optional)"
                        onChange={(e) => {
                          setJobTitle(e.target.value);
                        }}
                        value={jobTitle}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="pb-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  value={isPrivacyChecked}
                  class="w-4 h-4  mt-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                  onChange={(e) => {
                    setPrivacyChecked(e.target.checked);
                  }}
                />
                <div className="ms-2 text-sm font-medium text-blue-50 dark:text-gray-300 mt-0.5">
                  I agree to the{" "}
                  <span
                    className="text-blue-50 cursor-pointer mt-1"
                    onClick={() => {
                      navigate("/privacy-policy");
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    className="text-blue-50 cursor-pointer"
                    onClick={() => {
                      navigate("/terms-of-service");
                    }}
                  >
                    Terms of Service
                  </span>
                </div>
              </div>
              {isSubmitted && !isPrivacyChecked && (
                <div className="text-red-100 text-[13px]  mt-0.5">
                  Please check the terms and conditions
                </div>
              )}
            </div>
            <span>
              {errorMessage && (
                <span className="text-red-100 text-sm flex flex-row justify-center pb-1">
                  {errorMessage}
                </span>
              )}
              <button
                className={`w-full cursor-pointer flex flex-row justify-center items-center pb-2 px-4 h-12 border border-transparent text-sm font-medium rounded-md text-white bg-blue-50  hover:bg-opacity-90  focus:outline-none
                                ${
                                  loading ? "opacity-70 cursor-not-allowed" : ""
                                }
                                `}
                onClick={signupHandler}
              >
                {loading ? <Loader /> : "Create Account"}
              </button>
            </span>

            <div className="flex items-center justify-center mt-6">
              <span className="px-3 text-gray-500">Or continue with</span>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <button
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                onClick={googleLoginHandler}
              >
                <img
                  src={GoogleIcon}
                  className="w-4 h-4 mr-3 pt-1"
                  alt="Google"
                />
                <div className="font-sans font-semibold text-sm">
                  Continue with Google
                </div>
              </button>

              <button
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                onClick={msLoginHandler}
              >
                <img
                  src={microsoftIcon}
                  className="w-4 h-4 mr-3 pt-1"
                  alt="Microsoft"
                />
                <div className="font-sans font-semibold text-sm">
                  Continue with Microsoft
                </div>
              </button>
            </div>

            <div className="mt-6 text-center">
              <div
                className="text-blue-50 dark:text-white  dark:text-opacity-80 cursor-pointer"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Already a member? Login
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
