import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

const mobileDarkStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#191A1F",
    color: "white",
    border: "none",
    borderRadius: 8,
    padding: "0px !important",
  },
}

const mobileLightStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#191A1F",
    color: "white",
    border: "none",
    borderRadius: 8,
    padding: "0px !important",
  },
}

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "40%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#191A1F",
    color: "white",
    border: "none",
    borderRadius: 8,
    padding: "0px !important",
  },
};

const lightStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "40%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ECECEC",
    color: "black",
    border: "none",
    borderRadius: 8,
    padding: "0px !important",
  },
};

export const CustomModal = ({
  isOpen,
  handleClose,
  children,
  theme = "dark",
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={
        isMobile
          ? theme === "dark"
            ? mobileDarkStyles
            : mobileLightStyles
          : theme === "dark"
          ? customStyles
          : lightStyles
      }
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </Modal>
  );
};
