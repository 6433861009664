import React, { useEffect, useState } from "react";
import ggLogo from "../../assets/gglogo.png";
import ggDarkLogo from "../../assets/gglogo-dark.png";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.png";
import microsoftIcon from "../../assets/microsoft.png";
import { useGoogleLogin } from "@react-oauth/google";
import { setUserDetails } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { LuSun } from "react-icons/lu";
import { FaMoon } from "react-icons/fa6";
import { CustomModal } from "../../components/CustomModal";
import { FaTimes } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authUrl, setAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [isForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

 
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          toast.success("Logged in successfully");
          storeDispatch(setUserDetails(response?.data?.user));
          if (response.data.code === "1") {
            navigate("/");
          } else {
            navigate("/billing");
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 5000 });
    }
  };
  const checkPaymentStatus = async (email) => {
    try {
      const response = await axios.get(
        `${getAPIBaseUrl()}/check-user-payment-status?email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data && response.data.is_payment) {
        navigate("/");
      } else {
        navigate("/billing");
      }
    } catch (error) {
      navigate("/billing");
    }
  };

  const loginHandler = async () => {
    setLoading(true);

    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setLoading(false);
      setLoginErrorMessage("");
      toast.success("Logged in successfully");

      if (rememberMe) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
      } else {
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("password", password);
      }

      checkPaymentStatus(response?.data?.user?.email);
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setLoading(false);
      setLoginErrorMessage(error?.response?.data?.error);
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleSuccess(tokenResponse),
  });

  const msLoginHandler = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  const onSubmitHandler = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/forgot-password`,
        {
          email: resetEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAuthUrl = async () => {
      const response = await axios.get(`${getAPIBaseUrl()}/ms-auth-url`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAuthUrl(response.data.auth_url);
    };
    fetchAuthUrl();
  }, []);

  useEffect(() => {
    const savedEmail =
      localStorage.getItem("email") || sessionStorage.getItem("email");
    const savedPassword =
      localStorage.getItem("password") || sessionStorage.getItem("password");

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 dark:text-gray-100">
      <div className="flex flex-row justify-end ">
        <button
          onClick={toggleTheme}
          className=" text-gray-800 w-12 m-3 flex flex-row justify-center p-3 dark:text-gray-200 bg-gray-200 dark:bg-gray-800 rounded-md focus:outline-none"
        >
          {theme === "dark" ? <LuSun /> : <FaMoon />}
        </button>
      </div>

      <div className=" flex flex-col justify-center items-center  p-4">
        <div className="w-full max-w-2xl p-8 space-y-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className=" flex flex-row  justify-center">
            <img
              className=" h-14 w-1/2 "
              src={theme === "dark" ? ggDarkLogo : ggLogo}
            />
          </div>
          <div className=" text-sm font-medium text-center text-gray-500 rounded-lg grid grid-cols-2  pb-5">
            <div
              className=" w-full h-12  flex flex-row justify-center items-center text-gray-900 bg-neutral-1000 dark:bg-gray-700 cursor-pointer  hover:bg-neutral-1000 dark:text-gray-100 border-y border-l border-gray-300 dark:border-gray-700 rounded-s-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none"
              aria-current="page"
            >
              Login
            </div>
            <div
              className=" w-full h-12  flex flex-row justify-center items-center text-gray-900 bg-white dark:bg-gray-800 cursor-pointer  hover:bg-neutral-1000 dark:text-gray-100 border border-gray-300 dark:border-gray-700 rounded-r-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none"
              onClick={() => {
                navigate("/create-account");
              }}
            >
              Sign up
            </div>
          </div>
          <div className=" space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="mb-6  rounded-lg">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <div
                  className="ml-2 block text-sm text-blue-50 cursor-pointer"
                  onClick={() => {
                    setForgotPasswordModal(true);
                  }}
                >
                  Forgot Password
                </div>
              </div> */}
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 dark:text-indigo-400 focus:ring-indigo-500 dark:focus:ring-indigo-400 border-gray-300 dark:border-gray-600 rounded"
                  onChange={() => setRememberMe(!rememberMe)}
                  checked={rememberMe}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900 dark:text-gray-100"
                >
                  Remember me
                </label>
              </div>
            </div>

            <div>
              {loginErrorMessage && (
                <span className="text-red-100 text-sm flex flex-row justify-center pb-1">
                  {loginErrorMessage}
                </span>
              )}
              <button
                className="w-full cursor-pointer flex flex-row justify-center items-center py-2 px-4 h-12 border border-transparent text-sm font-medium rounded-md text-white bg-blue-50  hover:bg-opacity-90  focus:outline-none"
                onClick={loginHandler}
                disabled={
                  !email ||
                  !password ||
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                }
              >
                {loading ? <Loader /> : "Sign in"}
              </button>
            </div>

            <div className="flex items-center justify-center mt-6">
              <span className="px-3 text-gray-500 dark:text-gray-400">
                Or continue with
              </span>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <button
                className="w-full inline-flex justify-center items-center py-2 px-4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-sm font-medium text-gray-500 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none"
                onClick={googleLoginHandler}
              >
                <img src={GoogleIcon} className="w-6 h-6 mr-3 " />
                <div className="font-sans font-semibold text-sm">
                  Continue with Google
                </div>
              </button>

              <button
                className="w-full inline-flex justify-center items-center py-2 px-4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-sm font-medium text-gray-500 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none"
                onClick={msLoginHandler}
              >
                <img src={microsoftIcon} className="w-6 h-6 mr-3 " />

                <div className="font-sans font-semibold text-sm">
                  Continue with Microsoft
                </div>
              </button>
            </div>

            <div className="mt-6 text-center">
              <div
                className="text-blue-50 dark:text-white  dark:text-opacity-80 cursor-pointer"
                onClick={() => {
                  navigate("/create-account");
                }}
              >
                Not a member? Create account
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isForgotPasswordModal}
        handleClose={() => {
          setForgotPasswordModal(false);
        }}
        theme={theme}
      >
        <div className="flex items-center justify-center px-4 text-center bg-white dark:bg-black-900">
          <div className="inline-block align-bottom bg-white dark:bg-black-900 rounded-lg px-4 pt-5 pb-4 text-left sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="flex items-start justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                Forgot Password
              </h3>
              <button className="text-gray-400 dark:text-gray-200 hover:text-gray-500 focus:outline-none">
                <FaTimes className="w-5 h-5" />
              </button>
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-300 mt-3">
              Enter your email address below and we'll send you a link to reset
              your password.
            </div>
            <div className="mt-4">
              <input
                type="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200"
                placeholder="Enter your email address"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>

            <div className="mt-6">
              <button
                onClick={onSubmitHandler}
                className="w-full cursor-pointer flex flex-row justify-center items-center py-2 px-4 h-12 border border-transparent text-sm font-medium rounded-md text-white bg-blue-50  hover:bg-opacity-90  focus:outline-none"
              >
                {loading ? "Sending..." : "Send Reset Link"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Login;
